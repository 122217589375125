<template>
    <el-dialog
        width="500px"
        top="50px"
        :visible.sync="visible"
        :before-close="() => !$wait.is('*') && $emit('close')"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('employee_details.add_reminder') }}
                </p>
            </div>
        </span>

        <div class="mb-20 mt-8 px-10">
            <el-form ref="form" :model="form" :rules="rules" class="w-full" label-position="top">
                <el-form-item :label="$t('employee_details.date')" prop="date">
                    <el-date-picker
                        v-model="form.date"
                        :picker-options="pickerOptions"
                        type="date"
                        :clearable="false"
                    />
                </el-form-item>
                <el-form-item :label="$t('employees_details.text')" prop="content">
                    <el-input v-model="form.content" type="textarea" rows="6" resize="none" />
                </el-form-item>
                <el-form-item :label="$t('employees_details.via')">
                    <el-checkbox v-model="form.system" disabled :label="$t('employees_details.system')" />
                    <!-- <el-checkbox v-model="form.sms" :false-label="0" :true-label="1" label="sms" /> -->
                    <el-checkbox v-model="form.email" :false-label="0" :true-label="1" :label="$t('employees_details.email')" />
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('loading.add_reminder')" @click="add">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        employeeUuid: {
            type:     String,
            required: true,
        },
    },

    data() {
        return {
            form: {
                content: null,
                date:    this.$dayjs().add(1, 'day'),
                system:  true,
                sms:     0,
                email:   0,
            },
            rules: {
                content: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                date:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
            pickerOptions: {
                firstDayOfWeek: 1,
                disabledDate:   date => date.getTime() < this.$dayjs(),
            },
        };
    },

    methods: {
        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            try {
                this.$wait.start('loading.add_reminder');
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/reminders`, {
                    reminder_date: this.$dayjs(this.form.date).unix(),
                    note:          this.form.content,
                    sms:           0,
                    email:         this.form.email,
                });

                this.$notify.success(this.$t('common.success'));
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('loading.add_reminder');
            }
        },

        closeModal() {
            this.$refs.form.resetFields();
            this.form = {
                content: null,
                date:    this.$dayjs().add(1, 'day'),
                system:  true,
                sms:     0,
                email:   0,
            };
            this.$emit('close');
        },
    },
};
</script>
<style scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}
</style>
